<script setup>
import { ref, computed, onMounted } from "vue";
import anime from "animejs";
import Typewriter from "t-writer.js";

import Thinking from "./messages/Thinking.vue";

import { USER_MESSAGE, ASSISTANT_MESSAGE } from "@/enums";

const props = defineProps({
  message: { type: Object, required: true },
});

const htmlEl = ref(null);
const contentEl = ref(null);

const twriterOptions = {
  loop: false,
  animateCursor: false,
  typeColor: props.message.role == "user" ? "black" : "white",
};

onMounted(() => {
  anime({
    targets: htmlEl.value,
    opacity: [0, 1],
    translateY: [-30, 0],
    duration: 500,
    easing: "easeInSine",
  });
  // .finished.then(
  //   () => {
  //     const twriter = new Typewriter( contentEl.value, twriterOptions )
  //     twriter
  //       .removeCursor()
  //       .rest(500)
  //       .type( props.message.content )
  //       .start()
  //   } )
});

const icon_class = computed(() =>
  props.message.role == "user" ? "fa fa-user" : "fa fa-robot"
);
</script>

<template>
  <div :class="['message', message.role]" ref="htmlEl">
    <div class="message-icon">
      <i :class="icon_class"></i>
    </div>
    <div class="message-content">
      <div style="white-space: pre-line">
        {{ message.content }}
      </div>
      <Thinking v-if="message.type == ASSISTANT_MESSAGE.LOADING" />
    </div>
  </div>
</template>
<style scoped>
.message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-icon {
  /* margin-right: 10px; */
  margin: 0 10px;
  font-size: 20px;
}

.message-content {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
}

.message.user {
  flex-direction: row-reverse;
  /* bordeR: 2px solid blueviolet; */
}

.message.user .message-content {
  background-color: #cce5ff;
  align-self: flex-end;
  border-top-left-radius: 0px;
  max-width: 50%;
}

.message.assistant {
  /* border: 2px solid red; */
}

.message.assistant .message-icon {
  /* margin-right:0; */
  /* margin-left: 10px; */
}

.message.assistant .message-content {
  background-color: #5b85b3;
  align-self: flex-start;
  border-top-right-radius: 0px;
  color: white;
  max-width: 50%;
}
</style>
