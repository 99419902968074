<template>
  <div class="chat-content" ref="chatContent">
    <ChatMessage
      v-for="(message, idx) in messages"
      :key="idx"
      :message="message"
    />
    <div class="chat-anchor" ref="chatAnchor"></div>
  </div>
</template>

<script>
import ChatMessage from "./ChatMessage.vue";

export default {
  name: "ChatContent",
  components: {
    ChatMessage,
  },
  props: {
    messages: {
      type: Array,
      required: true,
      resizeObserver: null,
    },
  },
  mounted() {
    const chatAnchor = this.$refs.chatAnchor;
    // console.log('chat content mounted called'.toUpperCase(), chatAnchor.scrollIntoView )
    chatAnchor.scrollIntoView();
    this.resizeObserver = new ResizeObserver((entries) => {
      chatAnchor.scrollIntoView();
    });
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.chat-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  box-sizing: border-box;

  scrollbar-color: #0056b3 lightgray;
  scrollbar-width: thin;
}

.chat-content * {
  overflow-anchor: none;
}

.chat-anchor {
  width: 50%;
  margin: auto;
  height: 1px;
  background-color: orangered;
  opacity: 0;

  overflow-anchor: auto;
}
</style>
